import React from 'react';
import { MDBContainer, MDBIcon, MDBRow, MDBCol } from "mdbreact";
import TabsComponent from '../../../SharedComponents/Tabs/Tabs';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import MenuField from '../../../SharedComponents/MenuField/MenuField';
import PopOver from '../../../SharedComponents/PopOver/PopOver';
import { submissionTabList, submissionDetailsTabList } from '../../../../constants/TabList';
import SubmissionDetails from './SubmissionDetails';
import SubmissionElements from './SubmissionElements';
import NewAssignedAnalyst from './NewAssignedAnalyst';
import MessageModal from '../../../SharedComponents/MessageModal';
import MainViewModalComponent from '../../../../Common/MainViewModalLayout/Modal/Modal';
import Loader from '../../../../Common/Loader/Loader';
import { submissionMenuOptions, submissionWorkflowMockData, SubmissionQueueInitialJson } from './Config';
import ScripterService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import './SubmissionDetailContainer.scss';
import MiscFiles from '../../../MiscFiles/MiscFiles';
import { filePayload } from '../../../MiscFiles/config';
import { withUserContext } from '../../../../contexts/UserContext';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 380,
        border: "1px solid #dadde9",
        marginTop: "-0.1rem",
    }
}))(Tooltip);

class SubmissionDetailContainer extends React.Component {
    constructor(props) {
        super(props); {
            this.state = {
                tabValue: 1,
                coverageTabValue: 1,
                showSubmissionModal: false,
                submissionId: null,
                submissionDetails: {},
                isFetchingSubmissionDetails: false,
                anchorEl: null,
                workflowAnchorEl: null,
                showMenuOptions: false,
                mode: null,
                fileCount: 0,
                showDeleteSubmissionPopUp: false,
                showSecondaryConfirmationPopup: false,
                showRecordCannotbeDeleted: false,
                submissionRefreshPage: false,
                fieldChanged: false,
                showError: false,
                showSuccess: false,
                showFail: false,
                showAssignCoverageModal: false,
                canViewSubmission: true,
                saveTitle: "Success",
                saveEditMessage: "The changes are saved successfully!",
                failTitle: "Failure",
                failMessage: "Saving changes failed!",
                unsavedTitle: "Unsaved Changes",
                unsavedMessage: "Are you sure you want to discard your changes?",
                submitSuccessMessage: "The submission has been submitted.",
                cancelSubmissionSuccessMessage: "The submission has been cancelled.",
                confirmSubmissionSuccessMessage: "The submission has been confirmed.",
                deleteSubmissionSuccessMessage: "The submission has been deleted.",
                coverageAssignedSuccessMessage: "The coverage has been assigned and the Story Analyst has been notified.",
                submitCoverageSuccessMessage: "The coverage has been submitted. The Feature Story Admin and the Story Analyst has been notified.",
                cancelCoverageSuccessMessage: "The coverage has been cancelled.",
                approveCoverageSuccessMessage: "The coverage has been approved.",
                fileUploadSuccessMessage: "File has been uploaded successfully!",
                saveAddQueue: "Submission has been added to your My Queue",
                saveRemoveQueue: "Submission has been removed from your Queue",
                failAddQueue: "Failed to Add in a Queue",
                failRemoveQueue: "Failed to Remove from Queue",
                getSubmissionDetails: () => this.getSubmissionDetails(),
                getFiles: () => this.getFiles(),
                showPageLoader: false,
                menuOptions: [
                    { value: 1, label: "Remove from My Queue" },
                    { value: 2, label: "Delete Submission" },
                ],
                projectStatusId: null,
                projectStatusName:null
            }
            this.fileRef = React.createRef();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if ((props.refreshPage?.value !== state.submissionRefreshPage) && props.refreshPage?.modal === 'submission') {
            if (props.refreshPage?.value) {
                state.getSubmissionDetails();
                state.getFiles();
                props.toggleRefreshPage(props.refreshPage?.modal)

            }
            return {
                submissionRefreshPage: props.refreshPage?.value || false
            }
        }
        return null;
    }

    componentDidMount() {
        this.setState({ submissionId: this.props.match?.params?.id, submissionRefreshPage: this.props.refreshPage?.value }, () => {
            this.getSubmissionDetails();
            this.getFiles();
        })
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.props.match?.params?.id !== prevState.submissionId) {
            this.setState({submissionId: this.props.match?.params?.id} ,() => {
                this.getSubmissionDetails();
                this.getFiles();
            })
        }
    }

    getFiles() {
        // let canAddRemoveViewPrivateSubmissionFiles=this.props?.userContext?.active_tenant?.permissions?.canAddRemoveViewPrivateSubmissionFiles === true?true:false
        let canAddPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateSubmissionFiles === true ? true : false
        let canViewPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canViewPrivateSubmissionFiles === true ? true : false
        let canRemovePrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateSubmissionFiles === true ? true : false
        let payload = {
            ...filePayload,
            submissionId: this.state?.submissionId,
            type: "submission",
            module: 'submission',
            subModule: 'misc',
            tenantName: this.props?.userContext?.active_tenant?.tenant_name,
            // isPrivate:canAddRemoveViewPrivateSubmissionFiles?1:0
            isPrivate:canAddPrivateSubmissionFiles || canViewPrivateSubmissionFiles || canRemovePrivateSubmissionFiles ? 1 : 0
        }
        ScripterService.getDataParams(Constants.scripterServiceBaseUrl + `/getFiles`, payload,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data?.error) {
                    console.log("Error from fetch file", response.data?.error)
                    return;
                }
                let count = response.data?.Contents?.length;
                this.setState({ fileCount: count })
            }, err => {
                console.log('from misc files', err)
            })
    }

    formatDate(content) {
        let findDate = content.match(/\d{2}([\/.-])\d{2}\1\d{4}/g);
        if (findDate) {
            let splitDate = findDate[0].split("/");
            const formatedDate = splitDate[1] + '/' + splitDate[0] + '/' + splitDate[2];
            return content.replace(findDate[0], formatedDate);
        } else {
            return content;
        }
    }

    getSubmissionDetails = () => {
        if (this.state.submissionId) {
            this.setState({ isFetchingSubmissionDetails: true })
            ScripterService.getData(Constants.scripterServiceBaseUrl + `/submission?submissionId=${this.state.submissionId}`, 
                this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    this.setState({ isFetchingSubmissionDetails: false })
                    if (response.data.error) {
                        console.log("response error", response)
                    } else {
                        if (response.data.length > 0) {
                            let submissionDetails = response?.data[0];
                            submissionDetails.submission_coverages = submissionDetails?.submission_coverages?.map(item => ({ ...item, isExpanded: false }))
                            submissionDetails.submission_coverages?.sort(function(a,b){
                                return new Date(a?.coverage_created) - new Date(b?.coverage_created);
                            });
                            submissionDetails.submission_progres = submissionDetails?.submission_progres?.split('<br>');
                            submissionDetails.akas = submissionDetails?.akas?.filter(item => item?.aka !== null && item?.aka !== 'null');
                            this.setState({
                                submissionDetails: submissionDetails,
                                canViewSubmission: submissionDetails?.is_view_submission,
                                projectStatusId: submissionDetails?.project_status_id,
                                projectStatusName: submissionDetails?.project_status_name
                            })
                        } else {
                            this.setState({ submissionDetails: {} })
                        }
                    }
                },
                    (err) => {
                        this.setState({ isFetchingSubmissionDetails: false })
                        console.log("Error in fetching submission details:", err)
                    })
        }
    }

    updateCoverageDetails = (id, coverageDetails) => {
        let newSubmissionDetails = { ...this.state.submissionDetails }
        let updatedCoverageItem = newSubmissionDetails.submission_coverages.findIndex(item => item.submission_coverage_id === id);
        newSubmissionDetails.submission_coverages.splice(updatedCoverageItem, 1, coverageDetails)
        this.setState({ submissionDetails: newSubmissionDetails })
        this.onChangeAnalystExpansion(updatedCoverageItem, true)
    }

    onChangeAnalystExpansion = (coverageIndex = null, isExpand) => {
        this.state.submissionDetails.submission_coverages = this.state.submissionDetails?.submission_coverages?.map((item, index) => {
            if (index === coverageIndex && isExpand) {
                item.isExpanded = true
            } else {
                item.isExpanded = false
            }
            return item;
        })
        this.setState({ submissionDetails: this.state.submissionDetails })
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }

    closePopup = () => {
        if (this.state.showSuccess) {
            if (this.state.refreshPage) {
                this.props?.toggleRefreshPage('submission')
            }
            if (this.state.mode === "delete_submission" || this.state.mode === "cancel_submission") {
                this.props.history.push("/");
            }
        }
        this.setState({ showError: false, showFail: false, showSuccess: false });
    }

    manageMessageModal = (mode, value, refreshValue, operation = null) => {
        switch (mode) {
            case 'Success':
                this.setState({ showSuccess: value, showFail: false, refreshPage: refreshValue, mode: operation })
                break;
            case 'Fail': this.setState({ showSuccess: false, showFail: value })
                break;
        }
    }

    getNewTabComponent = () => {
        // let canAddRemoveViewPrivateSubmissionFiles=this.props?.userContext?.active_tenant?.permissions?.canAddRemoveViewPrivateSubmissionFiles === true?true:false
        let canAddPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canAddPrivateSubmissionFiles === true ? true : false
        let canViewPrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canViewPrivateSubmissionFiles === true ? true : false
        let canRemovePrivateSubmissionFiles = this.props?.userContext?.active_tenant?.permissions?.canRemovePrivateSubmissionFiles === true ? true : false
        switch (this.state.tabValue) {
            case 1: return <SubmissionDetails
                submissionId={this.state.submissionId}
                submissionDetails={this.state.submissionDetails}
                manageMessageModal={this.manageMessageModal}
                toggleRefreshPage={this.props.toggleRefreshPage}
                isLoading={this.state.isFetchingSubmissionDetails || false}
                handleShowTalentPopup={this.props?.handleShowTalentPopup}
                { ...this.props }
            />
            case 2: return <SubmissionElements
                submissionId={this.state.submissionId}
                canModifySubmission={this.state.submissionDetails?.is_edit_submission}
                manageMessageModal={this.manageMessageModal}
                refreshPage={this.props.refreshPage}
                toggleRefreshPage={this.props.toggleRefreshPage}
                divisionId={this.state.submissionDetails?.division_id}
            />
            case 3: return <MiscFiles
                fileRef={this.fileRef}
                handleSubmissionDelete={this.handleSubmissionDelete.bind(this)}
                toggleRefreshPage={this.props.toggleRefreshPage}
               // canModifyFiles={this.state.submissionDetails?.is_edit_submission || canAddRemoveViewPrivateSubmissionFiles}
               canModifyFiles={canRemovePrivateSubmissionFiles}
               // showPrivateCheck={canAddRemoveViewPrivateSubmissionFiles}
               showPrivateCheck={canAddPrivateSubmissionFiles}
               viewPrivateFiles={canViewPrivateSubmissionFiles}
                divisionId={this.state.submissionDetails?.division_id}
                // filePermission={ 
                //     (this.props?.userContext?.user_profile?.group_name === 'Feature Story Admin' ||
                //         this.props?.userContext?.user_profile?.user_id === this.state.submissionDetails?.created_by
                //     )
                // }
                filePayload={{
                    ...filePayload,
                    submissionId: this.state?.submissionId,
                    type: "submission",
                    module: 'submission',
                    subModule: 'misc'
                }}
                toggleRefreshFileCount={(count) => this.setState({ fileCount: count })}
                isConfidential={this.state.submissionDetails?.is_confidential}
                projectStatusId={this.state.projectStatusId}
                projectStatusName={this.state.projectStatusName}
            />
        }
    }

    handleText = () => {
        let permissions = this.props?.userContext?.active_tenant?.permissions;
        switch (this.state.tabValue) {
            case 1: return this.state.submissionDetails?.is_edit_submission ? "Edit" : null
            case 2: return this.state.submissionDetails?.is_edit_submission ? "Edit elements" : null
            // case 3: return (this.state.submissionDetails?.is_edit_submission || permissions?.canAddRemoveViewPrivateSubmissionFiles) ? 
            //     "Add File" : null
            case 3: return this.state.submissionDetails?.is_edit_submission  ?"Add File" : null 
        }
    }

    handleIcon = () => {
        switch (this.state.tabValue) {
            case 1: return "pen"
            case 2: return "pen"
            case 3: return "plus-circle"
        }
    }

    showEditModal = (file) => {
        switch (this.state.tabValue) {
            case 1: return this.props.handleShowSubmissionPopup(this.state.submissionId)
            case 2: return this.props.handleShowEditElementModal(this.state.submissionId)
            case 3: this.fileRef.current.onFileUpload(file); break;
        }
    }

    handleSubmissionPopUp = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleSubmissionWorkflow = (event) => {
        this.setState({ workflowAnchorEl: event.currentTarget })
    }

    onClickCallback = (val) => {
        if (val?.label === 'Delete Submission') {
            this.setState({ showDeleteSubmissionPopUp: true })
        } else if (val?.label === 'Add To My Queue' || 'Remove from My Queue') {
            // functionality to add/remove to/from queue
            this.handleMyQueue(val?.label);
        }
    }

    handleMyQueue = (actionType) => {
        this.setState({ mode: actionType === "Add To My Queue" ? 'ADDQUEUE' : "REMOVEQUEUE", showPageLoader: true })
        let payload = {
            ...SubmissionQueueInitialJson,
            user_id: this?.props?.userContext?.user_profile?.user_id,
            submission_id: this.state.submissionId,
            is_delete: actionType === "Remove from My Queue" ? 1 : 0
        }
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + `/userQueue`, payload, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ showPageLoader: false })
                if (response.data?.error) {
                    this.manageMessageModal("Fail", true);
                    return;
                }
                this.manageMessageModal("Success", true, true);

            }, err => {
                this.setState({ showPageLoader: false })
                this.manageMessageModal("Fail", true);
                console.log("Error from Submission Queue", err)
            })
    }

    // manageMessageModal = (mode, value, refreshValue) => {
    //     switch (mode) {
    //         case 'Success':
    //             this.setState({ showSuccess: value, showFail: false, refreshPage: refreshValue })
    //             break;
    //         case 'Fail': this.setState({ showSuccess: false, showFail: value })
    //             break;
    //         case 'Error': this.setState({ showSuccess: false, showFail: false, showError: value })
    //             break;
    //     }
    // }

    handleConfirmDeletion = () => {
        if (this.state.submissionDetails && (this.state.submissionDetails?.submission_status === "Confirmed" &&
            this.state.submissionDetails?.submission_coverages?.length > 0)
        ) {
            this.setState({
                showDeleteSubmissionPopUp: false,
                showSecondaryConfirmationPopup: true
            })
        } else {
            this.handleSubmissionDelete()
        }
    }

    closeAccessMessageModal = () => {
        this.props.history.push("/");
    }

    handlePageRefresh = (page) => {
        if (page === 'submission') {
            this.props?.toggleRefreshPage('submission')
        }
    }

    findCoverageIndex = (coverageId) => {
        let coverageIndex = this.state.submissionDetails?.submission_coverages.findIndex(item => item.submission_coverage_id === coverageId)
        return coverageIndex;
    }

    handleSubmissionDelete = (fromFile) => {
        this.setState({ isPosting: true })
        let postJson = {...submissionWorkflowMockData};
        postJson.submission_id = this.state.submissionId;
        postJson.action_label = fromFile ? "FILE UPLOADED" : "DELETE SUBMISSION";
        postJson.created_at = new Date().toLocaleString('en-US', { hour12: false});
        ScripterService.postDataParams(Constants.scripterServiceBaseUrl + '/submissionCoverageWorkflow', postJson,
            this.props.userContext.active_tenant.tenant_id, this.state.submissionDetails?.division_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({
                        isPosting: false,
                        showSecondaryConfirmationPopup: false,
                        showDeleteSubmissionPopUp: false
                    })
                    this.manageMessageModal('Fail', true)
                } else {
                    if (postJson.action_label == "DELETE SUBMISSION" && (response.data && response.data.length > 0)) {
                        this.setState({
                            isPosting: false,
                            showSecondaryConfirmationPopup: false,
                            showDeleteSubmissionPopUp: false,
                            showRecordCannotbeDeleted: true
                        })
                    } else {
                        this.setState({
                            isPosting: false,
                            showSecondaryConfirmationPopup: false,
                            showDeleteSubmissionPopUp: false
                        });
                        this.manageMessageModal('Success', true, !fromFile ? true : false, !fromFile ? 'delete_submission' : 'file_uploaded')
                    }

                }
            },
                (err) => {
                    console.log("Error in confirming submission:", err)
                    this.setState({ isPosting: false, showSecondaryConfirmationPopup: false, showDeleteSubmissionPopUp: false });
                    this.manageMessageModal('Fail', true)
                })
    }

    render() {
        let message = this.state.mode === "submit" ?
            (this.state.showSuccess ? this.state.submitSuccessMessage : this.state.failMessage) :
            this.state.mode === "cancel_submission" ?
                (this.state.showSuccess ? this.state.cancelSubmissionSuccessMessage : this.state.failMessage) :
                this.state.mode === "confirm_submission" ?
                    (this.state.showSuccess ? this.state.confirmSubmissionSuccessMessage : this.state.failMessage) :
                    this.state.mode === "delete_submission" ?
                        (this.state.showSuccess ? this.state.deleteSubmissionSuccessMessage : this.state.failMessage) :
                        this.state.mode === "submit_coverage" ?
                            (this.state.showSuccess ? this.state.submitCoverageSuccessMessage : this.state.failMessage) :
                            this.state.mode === "cancel_coverage" ?
                                (this.state.showSuccess ? this.state.cancelCoverageSuccessMessage : this.state.failMessage) :
                                this.state.mode === "approve_coverage" ?
                                    (this.state.showSuccess ? this.state.approveCoverageSuccessMessage : this.state.failMessage) :
                                    this.state.mode === "file_uploaded" ?
                                        (this.state.showSuccess ? this.state.fileUploadSuccessMessage : this.state.failMessage) :
                                        this.state?.mode === "ADDQUEUE" ?
                                            (this.state.showSuccess ? this.state.saveAddQueue : this.state.failAddQueue) :
                                            this.state?.mode === "REMOVEQUEUE" ?
                                                (this.state.showSuccess ? this.state.saveRemoveQueue : this.state.failRemoveQueue) :
                                                (this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
                                                    this.state.showSuccess ? this.state.saveEditMessage : "");
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <>
                {!this.state?.canViewSubmission ?
                    <MessageModal
                        open={!this.state?.canViewSubmission || false}
                        title={"Access Denied"}
                        message={"You don't have access to view this submission!"}
                        primaryButtonText={"Ok"}
                        hideCancel={true}
                        onConfirm={this.closeAccessMessageModal}
                    /> :
                    <MDBContainer fluid id="submission-detail-container">
                        <Loader
                            isOpen={this.state.showPageLoader || false}
                            text={this.state.mode === 'ADDQUEUE' ? "Adding..." : "Removing..."}
                        />
                        <div className='submission'>
                            <div>
                                <span className='SubmissionTitle'>{this.state.submissionDetails?.submission_title || ''}</span>
                                {this.state.submissionDetails?.is_delete_submission ?
                                    <span className='ml-1'>
                                        <MDBIcon
                                            className="cursor-pointer"
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={this.handleSubmissionPopUp}
                                            icon={"ellipsis-h"}
                                        />
                                    </span> : ""
                                }
                                <HtmlTooltip
                                    title={
                                        <div className="historyTooltip">
                                            {this.state.submissionDetails?.submission_progres != '-' ?
                                                this.state.submissionDetails?.submission_progres?.map(content => (
                                                    <p className="historyTooltipText">{this.formatDate(content)}</p>
                                                )) : <p className="historyTooltipText">No History</p>
                                            }
                                        </div>
                                    }
                                    interactive={true}
                                    placement="bottom-start"
                                >
                                    <span className='ml-1'>
                                        <MDBIcon
                                            className="cursor-pointer"
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            // onClick={this.handleSubmissionWorkflow}
                                            icon={"info-circle"}
                                        />
                                    </span>
                                </HtmlTooltip>
                                <div className="submissionMenu">
                                    <MenuField
                                        // id={"delete-submission"}
                                        id={`${this.state.submissionDetails?.is_add_to_queue == 0 ? 'submission-menu-items' : 'menu-type'}`}
                                        anchorEl={this.state.anchorEl}
                                        open={this.state.anchorEl}
                                        // options={this.state.submissionDetails?.is_add_to_queue == 0 ? submissionMenuOptions : this.state.menuOptions || []}
                                        options={submissionMenuOptions || []}
                                        handleClose={() => this.setState({ anchorEl: null })}
                                        onClick={this.onClickCallback}
                                    />
                                </div>
                            </div>
                            <div>
                                {this.state.submissionDetails?.is_confidential ?
                                    <span className='Confidential'>Confidential</span> :
                                    <span className='NonConfidential'>Non-Confidential</span>
                                }
                            </div>
                        </div>
                        {/* <MDBRow>
                                <MDBCol md={8}></MDBCol>
                                <MDBCol>
                                    <span className='SubmissionStatus'>
                                        {this.state.submissionDetails?.submission_status}
                                    </span>
                                </MDBCol>
                            </MDBRow> */}
                        <div className='submission-tab'>
                            <div>
                                <TabsComponent
                                    tabList={submissionTabList}
                                    value={this.state.tabValue}
                                    badgeContent={this.state.fileCount || 0}
                                    onChange={(e, v) => { this.handleTabChange(v) }}
                                />

                            </div>
                            {this.handleText() &&
                                <div className='button_center'>
                                    <BasicButton
                                        variant="contained"
                                        type="inline"
                                        color="primary"
                                        text={this.handleText()}
                                        icon={this.handleIcon()}
                                        {...(this.state.tabValue === 3 && { uploadFile: (file) => this.showEditModal(file) })}
                                        {...(this.state.tabValue === 3 && { inputType: 'file' })}
                                        onClick={() => this.showEditModal()}
                                    />
                                </div>
                            }
                        </div>
                        {this.getNewTabComponent()}
                        {this.state.submissionDetails?.submission_status === "Confirmed" ?
                            <>
                                <MDBRow className="coverages">
                                    <MDBCol md={2}>
                                        <TabsComponent
                                            tabList={submissionDetailsTabList}
                                            value={this.state.coverageTabValue}
                                            onChange={(e, v) => this.setState({ coverageTabValue: v })}
                                        />
                                    </MDBCol>
                                    <MDBCol md={10} className="coverage-btn">
                                        <div className="flex">
                                            {this.state.submissionDetails?.is_assign_coverage ?
                                                <BasicButton
                                                    variant="contained"
                                                    text="Assign Coverage"
                                                    type="inline"
                                                    onClick={() => this.setState({ showAssignCoverageModal: true })}
                                                    icon={"user-plus"}
                                                /> : ""
                                            }
                                        </div>
                                    </MDBCol>
                                </MDBRow>

                                <div className="coverage-list">
                                    {this.state.submissionDetails?.submission_coverages?.map((coverage, index) => (
                                        <NewAssignedAnalyst
                                            index={index}
                                            submissionId={this.state.submissionId}
                                            coverage={coverage}
                                            confidential={this.state.submissionDetails?.is_confidential === 1}
                                            onChangeAnalystExpansion={this.onChangeAnalystExpansion}
                                            checkIfCoverageModalOpen={this.state.showAssignCoverageModal}
                                            manageMessageModal={this.manageMessageModal}
                                            handlePageRefresh={this.handlePageRefresh}
                                            findCoverageIndex={this.findCoverageIndex}
                                            updateCoverageDetails={this.updateCoverageDetails}
                                            submissionCompareTo={this.state.submissionDetails?.compare_to}
                                            submissionCoverageType={this.state.submissionDetails?.coverage_type}
                                            divisionId={this.state.submissionDetails?.division_id}
                                            submissionSubmittedTo={this.state.submissionDetails?.submitted_to}
                                        />
                                    ))}
                                </div>
                            </> : ""
                        }
                        {
                            this.state.showAssignCoverageModal &&
                            <MainViewModalComponent
                                open={this.state.showAssignCoverageModal}
                                handleClose={() => this.setState({ showAssignCoverageModal: false })}
                                headerText={"Assign a Coverage"}
                                mode={""}
                                modalName={"Assign Coverage"}
                                coverageSuccessMessage={this.state.coverageAssignedSuccessMessage}
                                submissionId={this.state.submissionId}
                                coverageDueDate={this.state.submissionDetails?.submission_due_date}
                                coverageTypeId={this.state.submissionDetails?.coverage_type_id}
                                compareToName={this.state.submissionDetails?.compare_to}
                                submissionCoverageType={this.state.submissionDetails?.coverage_type}
                                handlePageRefresh={this.handlePageRefresh}
                                divisionId={this.state.submissionDetails?.division_id}
                            />
                        }
                        {this.state.showDeleteSubmissionPopUp &&
                            <MessageModal
                                open={this.state.showDeleteSubmissionPopUp}
                                title={"Delete Submission"}
                                message={"Are you sure you would like to delete this submission? This action cannot be undone."}
                                primaryButtonText={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Ok"}
                                secondaryButtonText={"Cancel"}
                                onConfirm={this.handleConfirmDeletion}
                                handleClose={(e) => this.setState({ showDeleteSubmissionPopUp: false })}
                            />
                        }
                        {this.state.showRecordCannotbeDeleted &&
                            <MessageModal
                                open={this.state.showRecordCannotbeDeleted}
                                title={"Delete Submission"}
                                message={"Submission Can not be Deleted"}
                                primaryButtonText={"Ok"}
                                secondaryButtonText={"Close"}
                                onConfirm={(e) => this.setState({ showRecordCannotbeDeleted: false })}
                                handleClose={(e) => this.setState({ showRecordCannotbeDeleted: false })}
                            />
                        }
                        {this.state.showSecondaryConfirmationPopup &&
                            <MessageModal
                                open={this.state.showSecondaryConfirmationPopup}
                                title={"Delete Submission"}
                                message={"This Submission has active coverages. Please confirm if you would like to delete this Submission"}
                                primaryButtonText={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Ok"}
                                secondaryButtonText={"Cancel"}
                                onConfirm={() => this.handleSubmissionDelete(false)}
                                handleClose={(e) => this.setState({ showSecondaryConfirmationPopup: false })}
                            />
                        }

                        <MessageModal
                            open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                            title={comfirmModalTitle}
                            message={message}
                            hideCancel={this.state.showFail || this.state.showSuccess || false}
                            primaryButtonText={"OK"}
                            secondaryButtonText={"Cancel"}
                            onConfirm={() => this.closePopup()}
                            handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false, mode: "default" })}
                        />
                    </MDBContainer>
                }
            </>
        );
    }
}

export default withUserContext(SubmissionDetailContainer);